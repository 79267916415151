import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3eeb4256")
const _hoisted_1 = { class: "flex-container" }
const _hoisted_2 = { class: "cardContainer col text-white" }
const _hoisted_3 = { class: "cardContainer col text-white" }
const _hoisted_4 = { class: "flex-container" }
const _hoisted_5 = {
  key: 0,
  class: "cardContainer col"
}
const _hoisted_6 = {
  key: 1,
  class: "cardContainer banker-container col"
}
const _hoisted_7 = { class: "flex-container" }
const _hoisted_8 = { class: "cardContainer col text-white" }
const _hoisted_9 = { class: "cardContainer col text-white" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("BetLimit.Player")), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("BetLimit.Banker")), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.playerCard)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerCard.slice().reverse(), (card, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["card-data player-card", { tilted: idx == 0, hideCard: card == '' || card == null }]),
                key: idx
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["card-image", _ctx.cardStyle(card)])
                }, null, 2)
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      (_ctx.bankerCard)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bankerCard, (card, idx) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["card-data banker-card", { tilted: idx == 2, hideCard: card == '' || card == null }]),
                key: idx
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["card-image", _ctx.cardStyle(card)])
                }, null, 2)
              ], 2))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.playerScore()), 1),
      _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.bankerScore()), 1)
    ])
  ]))
}