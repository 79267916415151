
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "defensil-result-shoe",
  props: {
    shoes: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const shoeMatrix = computed(() => {
      return props.shoes.match(/.{1,6}/g) || [];
    });

    const shoText = (val) => {
      const bankerShoe = ["a", "b", "c", "d"],
        playerShoe = ["e", "f", "g", "h"],
        tieShoe = ["i", "j", "k", "l"];

      if (bankerShoe.includes(val)) return "B";
      else if (playerShoe.includes(val)) return "P";
      else if (tieShoe.includes(val)) return "T";
    };
    return {
      shoeMatrix,
      shoText,
    };
  },
});
