
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "defensil-result-card",
  props: {
    card: {
      type: Array,
    },
  },
  setup(props) {
    // // get the player cards
    const playerCard = computed(() => {
      var cards = "";
      if (props.card) {
        props.card.map(function (item: any) {
          if (item.type == "player" && item.cards) {
            cards = item.cards;
          }
        });
      }
      return cards;
    });

    // get the banker cards
    const bankerCard = computed(() => {
      var cards = "";
      if (props.card) {
        props.card.map(function (item: any) {
          if (item.type == "banker" && item.cards) {
            cards = item.cards;
          }
        });
      }
      return cards;
    });

    // get the player score
    const playerScore = () => {
      var score = 0;
      if (props.card) {
        props.card.map(function (item: any) {
          if (item.type == "player" && (item.score || item.score === 0)) {
            score = item.score;
          }
        });
      }
      return score % 10;
    };

    // get the banker score
    const bankerScore = () => {
      var score = 0;
      if (props.card) {
        props.card.map(function (item: any) {
          // score = 3;
          if (item.type == "banker" && (item.score || item.score === 0)) {
            score = item.score;
          }
        });
      }
      return score % 10;
    };

    // setup the card position and css
    const cardStyle = (card: string) => {
      var cardClass = "";

      if (card != null) {
        var cardNumber = card.slice(0, 1);
        if (
          cardNumber == "H" ||
          cardNumber == "S" ||
          cardNumber == "C" ||
          cardNumber == "D"
        ) {
          cardClass = card.toLowerCase();
        } else {
          switch (cardNumber.toString()) {
            case "1":
              cardClass = "01";
              break;
            case "A":
              cardClass = "01";
              break;
            case "2":
              cardClass = "02";
              break;
            case "3":
              cardClass = "03";
              break;
            case "4":
              cardClass = "04";
              break;
            case "5":
              cardClass = "05";
              break;
            case "6":
              cardClass = "06";
              break;
            case "7":
              cardClass = "07";
              break;
            case "8":
              cardClass = "08";
              break;
            case "9":
              cardClass = "09";
              break;
            case "T":
              cardClass = "10";
              break;
            case "10":
              cardClass = "10";
              break;
            case "J":
              cardClass = "11";
              break;
            case "Q":
              cardClass = "12";
              break;
            case "K":
              cardClass = "13";
              break;
          }

          if (card.slice(0, 2) != "10") {
            cardClass = card.slice(1, 2).toString().toLowerCase() + cardClass;
          } else {
            cardClass = card.slice(2, 3).toString().toLowerCase() + "10";
          }
        }
      }

      return cardClass;
    };

    return {
      bankerCard,
      bankerScore,
      cardStyle,
      playerCard,
      playerScore,
    };
  },
});
