import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "bead-road grid-y-repeat-pcg",
  ref: "shoe-tbl"
}
const _hoisted_2 = { class: "rs-ctnr d-flex flex-rowgrid-y-repeat-pcg" }
const _hoisted_3 = {
  class: "shoe-item shoe-text",
  style: {"height":"2.3vh","width":"2.3vh"}
}
const _hoisted_4 = { class: "res-span-gr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shoeMatrix, (shoeM, x) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "flex-column",
          key: x
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shoeM.split(''), (shoe, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              style: {"height":"2.6vh","width":"2.56vh"},
              class: _normalizeClass([[shoe], "shoeHover"])
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.shoText(shoe)), 1)
              ])
            ], 2))
          }), 128))
        ]))
      }), 128))
    ])
  ], 512))
}