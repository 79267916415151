
import { defineComponent, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import moment from "moment";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import DefensilResultShoe from "@/components/results/DefensilResultShoe.vue";
import DefensilResultCard from "@/components/results/DefensilResultCard.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";

interface IGameResult {
  roomno: string;
  roomorder: string;
  shoe: string;
  createdAt: Date;
}

interface DateRange {
  start: string;
  end: string;
}
interface Paginated {
  rows: number;
  page: number;
  limit: number;
  data: Array<any>;
}

export default defineComponent({
  name: "defensil-game-result",
  components: {
    Datatable,
    DateRangePicker,
    DefensilResultShoe,
    DefensilResultCard,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // table variables
    const tableHeader = ref([
      {
        name: t("GameRoom.Roomno"),
        key: "roomno",
        text: true,
        sortable: true,
      },
      {
        name: t("GameRoom.Roomorder"),
        key: "roomorder",
        text: true,
        sortable: true,
      },
      {
        name: t("GameRoom.Result"),
        key: "resultbead",
        text: true,
        sortable: true,
        customslot: true,
      },
      {
        name: t("GameRoom.Shoe"),
        key: "shoe",
        customslot: true,
      },
      {
        name: t("GameRoom.Card"),
        key: "card",
        customslot: true,
      },
      {
        name: t("Deposit.Date"),
        key: "createdAt",
        text: true,
      },
    ]);
    const tableData = ref<Array<IGameResult>>([]);

    /**
     * Search
     *
     */
    const search = ref({
      game: "CMB",
      roomno: "Maxi A",
    });

    const games = [
      { value: "CMB", label: t("Game.CMB") },
      { value: "AG", label: t("Game.AG") },
      { value: "CGY", label: t("Game.CGY") },
      { value: "DG", label: t("Game.DG") },
      { value: "EVO", label: t("Game.EVO") },
      { value: "OGP", label: t("Game.OGP") },
    ];
    const roomno = ref([]);
    let daterange = {
      start: moment().format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    };
    /**
     * Pagination
     *
     */
    const rowsCount = ref(0);
    let pageLimit = 50;
    let pageNumber = 1;

    const setSelectedDate = (date: DateRange) => (daterange = date);
    /**
     * Set Page Limit
     *
     */
    const setPageLimit = (limit: number) => {
      pageLimit = limit;
      // refresh
      getResults();
    };
    /**
     * Set Page
     *
     */
    const setPage = (page: number) => {
      pageNumber = page;
      // refresh
      getResults();
    };
    /**
     * Get Roomno
     *
     */
    const getRoomno = async () => {
      const data = await ApiService.get(`/game/roomno/${search.value.game}`)
        .then((res) => res.data)
        .catch(() => []);

      if (data.length > 0) search.value.roomno = data[0].roomno;
      roomno.value = data;
    };
    /**
     * Get Results
     *
     */
    const getResults = async () => {
      const results: Paginated = await ApiService.get(
        `/game/results/${search.value.game}/${search.value.roomno}?start=${daterange.start}&end=${daterange.end}&page=${pageNumber}&limit=${pageLimit}`
      )
        .then((res) => res.data)
        .catch(() => []);

      tableData.value.splice(0, tableData.value.length, ...results.data);
      rowsCount.value = Number(results.rows);
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("Menu.ResultManage"), []);
      getRoomno();
      getResults();
    });

    return {
      tableHeader,
      tableData,
      search,
      games,
      roomno,
      getRoomno,
      getResults,
      rowsCount,
      setPageLimit,
      setPage,
      setSelectedDate,
    };
  },
});
